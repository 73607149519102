import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c10bf93"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "upload container-fluid"
}
const _hoisted_2 = {
  key: 0,
  class: "row inputfield align-items-center"
}
const _hoisted_3 = { class: "col-8 align-self-center" }
const _hoisted_4 = { class: "col-4 align-self-center" }
const _hoisted_5 = {
  key: 0,
  class: "col-12"
}
const _hoisted_6 = {
  key: 1,
  class: "row uploading align-items-center"
}
const _hoisted_7 = { class: "col-8" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-12 filename" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "progress" }
const _hoisted_12 = ["aria-valuenow"]
const _hoisted_13 = { class: "col-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.deleted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.status != 'uploading' && _ctx.status != 'finished')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("input", {
                  type: "file",
                  onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeUploadfile && _ctx.changeUploadfile(...args)))
                }, null, 32)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("button", {
                  class: "btn-formular",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.upload && _ctx.upload(...args)))
                }, "Hochladen")
              ]),
              (_ctx.status == 'error')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
                        return (_openBlock(), _createElementBlock("li", { key: error }, _toDisplayString(_ctx.$t(error)), 1))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.status == 'uploading' || _ctx.status == 'finished')
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.filename), 1),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", {
                        class: "progress-bar bg-info",
                        role: "progressbar",
                        style: _normalizeStyle(`width: ${_ctx.percent}%;`),
                        "aria-valuenow": _ctx.percent,
                        "aria-valuemin": "0",
                        "aria-valuemax": "100"
                      }, _toDisplayString((_ctx.fileuploaded / 1000000).toFixed(2)) + "MB / " + _toDisplayString((_ctx.filesize / 1000000).toFixed(2)) + "MB ", 13, _hoisted_12)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                (!_ctx.media && _ctx.status == 'finished')
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn-formular",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.del && _ctx.del(...args)))
                    }, "Löschen"))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}